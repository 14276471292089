/* global */

$(document).foundation();

$.isInViewport = el => {
  const elementTop = $(el).offset().top + 500;
  const elementBottom = elementTop + $(el).outerHeight();
  const viewportTop = $(window).scrollTop();
  const viewportBottom = viewportTop + $(window).height();
  return elementBottom > viewportTop && elementTop < viewportBottom;
};

/* eslint-disable */
$(document).ready(function() {
  $(".promo-slider").slick({
    arrows: false,
    dots: true,
    infinite: true
  });

  $('.show-for-medium .more-from-wrapper').slick({
    slidesToShow: 5,
    slidesToScroll: 1,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
  })


  $(window).on("scroll", function() {
    var depth, i, layer, layers, len, movement, topDistance, translate3d;

    if ($(window).scrollTop() > $(".masonry").offset().top) {
      topDistance = this.pageYOffset;
      layers = document.querySelectorAll("[data-type='parallax']");
    }
  });
  

  $('.content-nav a').on('click', function(e){
    e.preventDefault();
    var id = $(this).attr('href');
    if (id.includes("#")) {
      e.preventDefault();
      if ($('.hide-for-medium').is(':visible') && id == '#menus') id = '#menus-mobile';
      $([document.documentElement, document.body]).animate({
        scrollTop: $(id).offset().top - 120
      }, 1300);
    }
  });
});

/* global AOS */
$(window).on("load", () => {
  AOS.init();
});

if ($(".more-from-image").length) {
  $(".more-from-image").hover(
    e => {
      const el = $(e.currentTarget);
      $(el).attr("src", $(el).attr("data-hover"));
    },
    e => {
      const el = $(e.currentTarget);
      $(el).attr("src", $(el).attr("data-src"));
    }
  );
}


$('body').on('touchstart', '.more-from-image2', function(e){
  const el = $(e.currentTarget).find('img');
  if(el.attr("data-click") === "false"){
    $(el).attr('src', $(el).attr('data-hover'));
    el.attr("data-click", "true")
  }else{
    $(el).attr('src', $(el).attr('data-src'));
    el.attr("data-click", "false")
  }

});


